import { Button, Col, Container, Row } from "react-bootstrap";
import {
  FILTER_ACTION,
  FILTER_NAMES,
  STATI,
} from "../../components/context/reducer";
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../../components/context/GlobalContextProvider";
import {
  PROJECTS,
  PROJECTS_TITLE,
} from "../../components/core/nav/NavigationItems";

import HomeShape from "../../components/core/ux/HomeShape";
import Layout from "../../components/core/Layout";
import ProjectTile from "../../components/core/ux/tiles/ProjectTile";
import ProjectsQuery from "../../components/queries/ProjectsQuery";
import React from "react";

const PROJECTS_PER_PAGE = 4;

const ProjectsIndexPage = () => {
  const projects = ProjectsQuery();
  const dispatch = React.useContext(GlobalDispatchContext);
  const state = React.useContext(GlobalStateContext);
  const [currentPage, setCurrentPage] = React.useState(1);
  const filterProjects = (projects) => {
    if (state.filters.length === 0) return projects;
    return projects.filter((p) =>
      state.filters.includes(p.frontmatter.projectstatus)
    );
  };
  const maxPage = Math.ceil(
    filterProjects(projects).length / PROJECTS_PER_PAGE
  );

  return (
    <Layout page={PROJECTS}>
      <HomeShape />
      <Container>
        <h2 className="text-uppercase h-muted">{PROJECTS}</h2>
        <h1 className="text-uppercase h bolder">{PROJECTS_TITLE}</h1>
        <div data-sal="slide-up" data-sal-duration="900">
          <h3 className="headline">
            Intelligent geplant – und erfolgreich realisiert
          </h3>
        </div>
        <div data-sal="slide-up" data-sal-duration="900">
          <p>
            <strong>new.space</strong> Projekte sind mit Weitblick entwickelt,
            nachhaltig geplant und gebaut – und haben immer den größtmöglichen
            Nutzen für den Menschen im Fokus. Für mehr Lebensqualität im höheren
            Alter. Für mehr Freiheit und Flexibilität in jedem Alter. Für
            Senioren, Studierende und junge Familien. Sehen Sie selbst, was{" "}
            <strong>new.space</strong> bereits realisiert hat – und was schon
            bald fertig gestellt wird.
          </p>
        </div>
        <hr className="my-4" />
        <div className="d-flex justify-content-center my-2">
          {STATI.map((f, i) => (
            <div key={i} style={{ paddingRight: "1em" }}>
              <input
                id={f}
                type="checkbox"
                checked={state.filters.includes(f)}
                onChange={() => {
                  dispatch({
                    type: FILTER_ACTION,
                    value: state.filters.includes(f)
                      ? state.filters.filter((p) => p !== f)
                      : //: [...state.filters, f],
                        [f],
                  });
                }}
              />{" "}
              <label
                htmlFor={f}
                style={{ visibility: "visible", verticalAlign: "top" }}
              >
                {FILTER_NAMES[f]}
              </label>
            </div>
          ))}
          {/*
          <Button
            onClick={() => {
              dispatch({
                type: FILTER_ACTION,
                value: [],
              });
            }}
            style={{
              margin: "-9px 0 9px 1em",
            }}
          >
            reset
          </Button>
          */}
        </div>
        <div className="my-2">
          {filterProjects(projects).length > 0 ? (
            Array.from({ length: currentPage }, (_, i) => i + 1).map((p, i) => {
              const start = (p - 1) * PROJECTS_PER_PAGE;
              const end = start + PROJECTS_PER_PAGE;
              const filteredChunk = filterProjects(projects).slice(start, end);
              return (
                <>
                  <Row>
                    <Col md={6} style={{ padding: "0 1em 0 0" }}>
                      <div>
                        <ProjectTile
                          file={filteredChunk.shift()}
                          isPortrait={false}
                        />
                      </div>
                      <div>
                        <ProjectTile
                          file={filteredChunk.shift()}
                          isPortrait={false}
                        />
                      </div>
                    </Col>
                    <Col md={6} style={{ padding: "0 1em 0 0" }}>
                      <ProjectTile
                        file={filteredChunk.shift()}
                        isPortrait={true}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} style={{ padding: "0 1em 0 0" }}>
                      <ProjectTile
                        file={filteredChunk.shift()}
                        isPortrait={false}
                      />
                    </Col>
                  </Row>
                </>
              );
            })
          ) : (
            <p>
              Mit dem ausgewählten Filter sind leider keine Ergebnisse
              vorhanden.
            </p>
          )}
        </div>
        {currentPage < maxPage && (
          <Container className="text-center my-5">
            <Button
              onClick={() => setCurrentPage((prev) => ++prev)}
              title="Mehr Projekte zeigen"
            >
              more
            </Button>
          </Container>
        )}
      </Container>
    </Layout>
  );
};

export default ProjectsIndexPage;
